// @ts-strict-ignore
import _ from 'lodash';
import { getYAxisConfig } from '@/trendData/yAxis.actions';
import { DASH_STYLES, ITEM_DATA_STATUS, ITEM_TYPES, Y_AXIS_TYPES } from '@/trendData/trendData.constants';
import { BaseItemStore } from '@/trendData/baseItem.store';

export const NON_NUMERICAL_SCALAR_CHART_VALUE = 1;

/**
 * A store for containing scalars which can be displayed on the chart. A scalar is composed of a single y-axis value.
 *
 * This store is augmented with additional functionality from sqBaseSignalStore.
 */

export class TrendScalarStore extends BaseItemStore {
  static readonly storeName = 'sqTrendScalarStore';

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @returns {Object} The dehydrated items.
   */
  dehydrate() {
    return {
      items: _.chain(this.state.get('items'))
        .filter(this.shouldDehydrateItem)
        .map((item) => this.pruneDehydratedItemProps(item))
        .value(),
    };
  }

  /**
   * Re-creates the scalars. All necessary data needed to rehydrate is persisted so no actions have to be called.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   * @return {Object} A promise that is fulfilled when items are completely rehydrated.
   */
  rehydrate(dehydratedState) {
    this.state.set(
      'items',
      _.map(dehydratedState.items, (item) =>
        this.createScalar(
          item.id,
          item.name,
          item.lane,
          item.alignment,
          _.omit(item, ['id', 'name', 'lane', 'alignment']),
        ),
      ),
    );
  }

  /**
   * Adds a scalar item.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - ID of the new item
   * @param {String} payload.name - Name of the new item
   * @param {String} payload.lane - Lane of the new item
   * @param {String} payload.alignment - Alignment of the new item
   * @param {String} [payload.color] - Color hex code (e.g. #CCCCCC)
   */
  addScalar(payload) {
    this.state.push(
      'items',
      this.createScalar(payload.id, payload.name, payload.lane, payload.alignment, _.pick(payload, ['color'])),
    );
  }

  /**
   * Adds additional data to an item.
   *
   * @param {Object} payload - Object container for arguments
   * @param {String} payload.id - ID of the item
   * @param {Number} payload.value - Value of the scalar
   * @param {Number} payload.lower - the lower bound if this scalar has a shadedArea
   * @param {Number} payload.upper - the upper bound if this scalar has a shadedArea
   * @param {Number} payload.start - the start time of the scalar. Normally the start of the display range. Used to
   * show labels for the scalar
   * @param {Number} payload.end - the end time of the scalar
   * @param {Number} payload.warningCount - the count of warnings that will be passed to the base item store
   * @param {Object[]} payload.warningLogs - the log of warnings that will be passed to the base item store
   */
  addValue(payload) {
    const isStringSeries = _.isString(payload.value) || _.isBoolean(payload.value);
    const cursor = this.getItemCursor(payload.id);
    if (cursor.exists()) {
      this.setDataStatusTo(
        ITEM_DATA_STATUS.PRESENT,
        _.pick(payload, ['id', 'warningCount', 'warningLogs', 'timingInformation', 'meterInformation']),
      );

      if (isStringSeries || _.isFinite(payload.value)) {
        const value = isStringSeries ? NON_NUMERICAL_SCALAR_CHART_VALUE : payload.value;
        cursor.merge({
          pointValue: payload.value,
          isStringSeries,
          data: [
            [payload.start, value],
            [payload.end, value],
          ],
        });
      } else if (_.isFinite(payload.lower) && _.isFinite(payload.upper) && payload.lower < payload.upper) {
        cursor.merge({
          pointValueLower: payload.lower,
          pointValueUpper: payload.upper,
          isStringSeries: false,
          data: [
            [payload.start, payload.lower, payload.upper],
            [payload.end, payload.lower, payload.upper],
          ],
        });
      } else {
        cursor.merge({
          isStringSeries: false,
          data: [],
        });
      }

      cursor.merge(_.assign(getYAxisConfig(payload.id), payload));
      if (isStringSeries) {
        cursor.set('stringEnum', [
          {
            key: NON_NUMERICAL_SCALAR_CHART_VALUE,
            stringValue: payload.value,
          },
        ]);
      }
    }
  }

  /**
   * Private helper function to add a scalar to the store using the specified properties.
   *
   * @param {String} id - ID to use for the new scalar
   * @param {String} name - Name to use for the new scalar
   * @param {String} lane - Lane to use for the new series
   * @param {String} alignment - Alignment to use for the new series
   * @param {Object} props - Object containing properties to apply to the new scalar
   * @returns {Object} Newly created scalar object.
   */
  createScalar(id, name, lane, alignment, props) {
    return this.createItem(
      id,
      name,
      ITEM_TYPES.SCALAR,
      _.assign(
        {
          data: [],
          dashStyle: DASH_STYLES.DASH,
          isStringSeries: false,
          yAxisConfig: {},
          statistics: {},
          lane,
          axisAlign: alignment,
          axisVisibility: true,
          axisAutoScale: true,
          visible: true,
          lineWidth: 1,
          yAxisType: Y_AXIS_TYPES.LINEAR,
          dataStatus: ITEM_DATA_STATUS.INITIALIZING,
          statusMessage: '',
        },
        props,
      ),
    );
  }

  localHandlers = {
    TREND_ADD_SCALAR: this.addScalar,
    TREND_SCALAR_RESULTS_SUCCESS: this.addValue,
  };

  protected readonly handlers = { ...this.localHandlers, ...this.baseHandlers };
}
